import React from "react"
import { Text } from "../core/commonExports"
import { BackButton, Input, InputContainer, InputLabel, mandatoryField, MultipleGoodsPrompt, ProgressBarComp } from "./common"
import { Button } from "../core/Button"
import Multiselect from "../Multiselect"

import { getCompactTypeOfFacility } from "./sharedUtils"
import useDebouncedValue from "../../hooks/useDebouncedValue"
import useAutoSave from "./hooks/useAutoSave"
import { formatPastedSiValue, getFormattedSIAmount, preventIncorrectInput } from "./sumInsuredSharedUtils"
import { styledRupeeSymbol, SumInsuredItem } from "./SumInsuredSharedComps"
import { getOccupancy, getPrefillGoodsData } from "./materialsDataCollectionUtils"

import MoneyIcon from "../../assets/images/campaigns/sum-insured-items/money.svg"

const goodsMaterialOptions = {
  "Motor vehicle showroom": ["Cars", "Bikes", "Scooters", "Electric Vehicles", "Spare Parts"],
  "Motor vehicle garage": ["Cars", "Bikes", "Scooters", "Electric Vehicles", "Spare Parts"],
  "Shop:-Retailing of FMCG/Grocery products": ["FMCG/Grocery Products (fruits, vegetables, detergents, rice etc)"],
  "Shop:-Retailing of medical/pharmaceutical products": ["Medicines"],
  "Shop:-Retailing of electronic/electrical products": ["TVs", "Refrigerators", "Mobile Phones", "Mobile Accessories", "Ovens"],
  "Shop:-Retailing of hardware products": ["Nuts", "Bolts"],
  "Shop:-Retailing of jewellery": ["Gold", "Silver", "Diamond"],
  "Shop:-Retailing of garments": ["Garments"],
  "Shop:-Retailing of plastic products": ["Plastic furniture items", "Plastic bags"],
  "Shop:-Retailing of chemical products/fertilizers": ["Agriculture fertilizers"],
  "Shop:-Retailing of paints": ["Paints"],
  "Shop:-Retailing of Furniture": ["All types of wooden furniture"],
  "Shop:-Retailing of footwear": ["All kinds of footwear"],
  "Shop:-Retailing of stationery products": ["Stationery products"],
  "Shop:-Beauty Salon, Beauty Parlour, Cosmetic shop": ["Beauty products"],
  "Restaurant/Cafe/Hotel/Resort/Canteen/Cloud Kitchen:-Restaurant": ["Food Products"],
  "Hospitals, Clinics, Diagnostic centres": ["Diagnostic equipment", "Medicines"],
  "Petrol, Diesel Pump:-Without CNG": ["Petrol", "Diesel"],
  "Petrol, Diesel Pump:-With CNG": ["Petrol","Diesel","CNG"],
  "Laundries / Dry Cleaning": ["Garments"],
  "Indoor stadiums, Health Club, Gymnasium and Swimming pool": ["Gym equipment"],
}

export const GoodsQuestion = ({typeOfFacility, natureOfBusiness, goods, setGoods}) => {
  const compactToF = getCompactTypeOfFacility(typeOfFacility)

  let goodsPlaceholder = ""

  switch (natureOfBusiness) {
    case 'Retailing of FMCG/Grocery products':
      goodsPlaceholder = "Example: Dal, Oil, Sugar, Rice, Dairy Products"
      break
    case 'Retailing of medical/pharmaceutical products':
      goodsPlaceholder = "Example: Medicines, Pharmaceutical Products, Ayurvedic Products"
      break
    case 'Retailing of garments':
      goodsPlaceholder = "Example: Shirts, T-Shirts, Jeans, Sarees, Suits"
      break
    case 'Retailing of electronic/electrical products':
      goodsPlaceholder = "Example: Mobile phones, Accessories, TV, Wires, Sockets"
      break
    case 'Retailing of hardware products':
      goodsPlaceholder = "Example: Locks, Pipes & Fittings, Doors"
      break
    case 'Retailing of jewellery':
      goodsPlaceholder = "Example: Necklace, Rings, Earrings, Diamonds, Gold, Silver, Artificial Jewellery"
      break
    case 'Retailing of plastic products':
      goodsPlaceholder = "Example: Bottles, Cups, Plates, Toys"
      break
    case 'Retailing of chemical products/fertilizers':
      goodsPlaceholder = "Example: Epoxy, Fertilizers, Pesticides, Adhisives, Calcium Chloride"
      break
    case 'Retailing of paints':
      goodsPlaceholder = "Example: Paints, Varnish, Powder Coating, POP, Gypsum"
      break
    case 'Retailing of Furniture':
      goodsPlaceholder = "Example: Chairs, Beds, Tables"
      break
    default:
  }

  switch (typeOfFacility) {
    case 'Motor vehicle garage':
      goodsPlaceholder = "Example: Screws, Nuts, Bolts, Cables, Batteries, Ball bearings"
      break
    case 'Motor vehicle showroom':
      goodsPlaceholder = "Example: Cars, Scooters, Motorcycles, Electric vehicles"
      break
    case 'Restaurant/Cafe/Hotel/Resort/Canteen/Cloud Kitchen':
      goodsPlaceholder = "Example: Dal, Grains, Vegetables"
      break
    default:
  }

  const occupancy = getOccupancy(typeOfFacility, natureOfBusiness)
  const options = goodsMaterialOptions[occupancy] || []
  const {selectedValues, otherDesc} = getPrefillGoodsData(options, goods)

  return (
    <InputContainer>
      <InputLabel htmlFor="totalSi" desktopStyles={{marginBottom: "8px"}}>
        {compactToF === "Shop" ? 
          <>What goods are you selling at your shop? {mandatoryField}</>
          : `Which goods are present at your ${compactToF}?`
        }
      </InputLabel>
      {/* accept normal freetext response if no dropdown values for the occupancy */}
      {!options.length ? (
        <Input value={goods} onChange={e => setGoods(e.target.value)} placeholder={goodsPlaceholder} />
      ) : (
        <Multiselect 
          options={options} 
          selectedValues={selectedValues}
          onOptionChange={setGoods}
          otherDesc={otherDesc}
          otherDescPlaceholder={goodsPlaceholder}
          showOtherOption
        />
      )}
      <MultipleGoodsPrompt />
    </InputContainer>
  )
}

const GoodsDataCollection = ({
  goods,
  setGoods,
  stockData,
  setStockData,
  sumInsuredValues,
  setSumInsuredValues,
  setTotalSiVal,
  getSiBreakdown,
  typeOfFacility,
  natureOfBusiness,
  onFormSubmit,
  onGoBack,
  newTheme,
  autoSaveData,
  isAutoSaving,
}) => {
  const goodsAmount = stockData?.goods || ''
  const compactToF = getCompactTypeOfFacility(typeOfFacility)
  const debouncedGoodsData = useDebouncedValue(goods, 800)
  const debouncedSumInsured = useDebouncedValue(sumInsuredValues, 800)

  const totalSI = React.useMemo(() => 
    Object.values(sumInsuredValues)
      ?.reduce((prev, curr) => Number(prev) + Number(curr), 0), 
    [sumInsuredValues]
  )

  const disableNext = isAutoSaving 
    || ((debouncedGoodsData || goods) && !goodsAmount) 
    || (typeOfFacility === "Shop" && !debouncedGoodsData)
    || (goods.endsWith(';'))

  const updateSiValues = (val) => {
    setStockData(prevStockData => ({
      ...prevStockData,
      goods: val
    }))
    setSumInsuredValues(prevSIValues => ({
      ...prevSIValues,
      Stock: val,
    }))
  }

  const handleSIValuePaste = (e) => {
    e.preventDefault()
    const numericValue = formatPastedSiValue(e)
    updateSiValues(numericValue)
  }

  const setSIValue = (e) => {
    const val = e.target.value?.replaceAll(',', '') || ''
    updateSiValues(val)
  }

  const handleFormSubmit = (e) => {
    onFormSubmit(e, totalSI)
  }

  useAutoSave(() => {
    autoSaveData("goodsData", {NatureOfStock: debouncedGoodsData})
    if (!debouncedGoodsData) {
      updateSiValues("") // reset stock here
    }
  }, [debouncedGoodsData])

  useAutoSave(() => {
    const data = {
      AssetValue: Number(totalSI),
      SumInsuredBreakdown: getSiBreakdown(debouncedSumInsured),
    }
    setTotalSiVal(totalSI)
    autoSaveData("goodsSiValue", data)
  }, [debouncedSumInsured])

  return (
    <form style={{display: 'flex', flexDirection: 'column', width: "100%"}} onSubmit={onFormSubmit}>
      <div style={{display: "flex", gap: "1rem", paddingRight: "1rem"}}>
        <BackButton handleClick={onGoBack} />
        <ProgressBarComp progress="78%" style={{margin: "0.75rem 0"}} />
      </div>
      <Text style={{alignSelf: "center", marginBottom: "1.5rem", textAlign: "center"}} fontSize="18px" mfontSize="18px" lineHeight="22.68px" color="#8668AD" fontWeight="bold">
        Tell us about the goods at your {compactToF}
      </Text>
      <GoodsQuestion
        goods={goods}
        setGoods={setGoods}
        typeOfFacility={typeOfFacility}
        natureOfBusiness={natureOfBusiness}
      />
      {goods ? (
        <SumInsuredItem>
          <MoneyIcon className="icon" />
          <InputContainer>
            <InputLabel htmlFor="si-field-goods" desktopStyles={{marginBottom: "8px"}}>
              Value of goods {mandatoryField}
            </InputLabel>
            <div style={{position: "relative"}}>
              {styledRupeeSymbol}
              <Input 
                type="text"
                name="si-field-goods"
                style={{paddingLeft: "2rem"}} 
                value={getFormattedSIAmount(goodsAmount)} 
                onChange={setSIValue} 
                onKeyDown={preventIncorrectInput} 
                onPaste={handleSIValuePaste} 
                placeholder="Enter amount"
              />
            </div>
          </InputContainer>
        </SumInsuredItem>
      ) : null}
      <Button
        wide
        label="Proceed"
        type={newTheme ? "flatdesign" : "secondary"}
        uppercaseLabel={false}
        onClick={handleFormSubmit}
        disabled={disableNext}
        style={{alignSelf: "center"}}
      />
    </form>
  )
}

export default GoodsDataCollection