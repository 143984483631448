export const getOccupancy = (typeOfFacility, natureOfBusiness) => {
  return natureOfBusiness 
    ? `${typeOfFacility}:-${natureOfBusiness}` 
    : typeOfFacility
}

export const getPrefillGoodsData = (options, goods) => {
  const goodsList = goods?.split(';')
  let selectedValues = []
  let otherDesc = ''
  goodsList.forEach(x => {
    if (options.includes(x)) {
      selectedValues.push(x)
    } else {
      otherDesc += x
    }
  })
  return {selectedValues, otherDesc}
}