import { useEffect } from "react"
import useIsInitialMount from "../../../hooks/useIsInitialMount"

function useAutoSave(func, deps) {
  const isInitialMount = useIsInitialMount()

  useEffect(() => {
    if (!isInitialMount) {
      func()
    }
  }, [...deps])
}

export default useAutoSave