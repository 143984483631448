import React from "react"
import styled from "styled-components";

export const styledRupeeSymbol = <span style={{position: "absolute", top: "0.75rem", left: "1rem", color: "#02475E"}}>₹</span>

export const SumInsuredItem = styled.div`
  display: flex; 
  align-items: flex-end; 
  gap: 1rem;
  width: 100%;
  .icon {
    margin-bottom: 3rem;
  }

  @media screen and (max-width: 768px) {
    flex-direction: row-reverse;
    gap: 0.5rem;
    .icon {
      margin-bottom: 2rem;
    }
  }
`