// for onkeydown events
export const preventIncorrectInput = e => {
  const val = e.target.value
  const onlyOneDecimal = (val.match(/\./g) || []).length < 1
  if (e.key === "." && onlyOneDecimal)  return
  if (e.key === "Backspace" || e.key === "Delete") return
  if (isNaN(e.key) || e.key === " ") {
    e.preventDefault()
  }
}

// for formatting pasted values
export const formatPastedSiValue = (e) => {
  e.preventDefault()
  const val = e.clipboardData
    .getData('text/plain')
    .trim()
    .replace(/\D*/gi, '')
  return parseInt(val) || ""
}

export const getFormattedSIAmount = (val) => {
  const trimmedVal = val?.toString()?.trim()?.replace(/[^0-9.]/g, '')
  if (trimmedVal === "") {
    return ""
  }
  if (!isNaN(trimmedVal)) {
    const formattedAmount = Number(trimmedVal).toLocaleString('en-IN')
    return formattedAmount
  }
  return val
}