// Ref: https://stackoverflow.com/a/56267719
import { useRef, useEffect } from 'react';

// will return true for initial mount of component
const useIsInitialMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

export default useIsInitialMount