import React from "react"
import styled from "styled-components";
import {Text} from "../core/commonExports"
import { Button } from "../core/Button";

import InfoIcon from "../../assets/images/info-icon.svg"
import ChevronDown from "../../assets/images/chevron_down.svg";

export const InputLabel = ({htmlFor = "", style={}, mobileStyles={}, desktopStyles={}, children}) => {
  return (
    <label htmlFor={htmlFor}>
      <Text fontSize="14px" mfontSize="12px" lineHeight="15px" mlineHeight="15px" color={"#6C6C6C"} style={{textAlign: "left", marginTop: "0", ...style}} mobileStyles={{marginBottom: "4px", ...mobileStyles}} desktopStyles={desktopStyles}>
        {children}
      </Text>
    </label>
  )
}

export const PhoneNoInput = ({style={}, ...props}) => {
  return (
    <div style={{position: "relative"}}>
      <PhoneNoPrefix>+91</PhoneNoPrefix>
      <Input 
        {...props}
        style={{...style, paddingLeft: "3rem"}}
      />
    </div>
  )
}

export const mandatoryField = <span style={{color: "#f04742"}}>*</span>

export const ProgressBarComp = ({progress = "", style={}}) => {
  const lastStep = progress === "94%"
  const secondLastStep = progress === "88%"
  const displayCompletionTag = secondLastStep || lastStep
  return (
    <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
      {displayCompletionTag ? (
        <Text fontSize="12px" mfontSize="10px" color="#57BA70" fontWeight="600" desktopStyles={{padding: "0.25rem 0.7rem"}} mobileStyles={{padding: "0 0.5rem"}} style={{ background: "rgb(247 244 244)", borderRadius: "30px", alignSelf: "end", margin: "6px 0", marginTop: "0", width: "fit-content"}}>
          {lastStep ? "Last Step!" : "Almost there!"}
        </Text>
      ) : null}
      <div style={{position: "relative", width: "100%", ...style}}>
        <Progressbar />
        <Progressbar color="#57BA70" progress={progress} />
      </div>
    </div>
  )
}

export const MultipleGoodsPrompt = () => (
  <MultipleGoodsPromptContainer>
    <InfoIcon className="i-icon" />
    <Text fontSize="14px" lineHeight="17.94px" mfontSize="12px" mlineHeight="15px" fontWeight="500" color="#AAAAAA">
      You can add multiple goods  
    </Text>
  </MultipleGoodsPromptContainer>
)

export const BackButton = ({
  handleClick = () => {}
}) => (
  <BackBtnContainer>
    <Button 
      onClick={handleClick}
      type="button" // to prevent triggering form submit action
      label=""
      minWidth="auto"
      btnIcon={<ChevronDown className="back-arrow-icon" />}
      style={{border: "none", borderRadius: "8px", width: "35px", height: "35px", padding: "12px", background: "#F0F0FD", boxShadow: "none"}}
    />
  </BackBtnContainer>
)

const BackBtnContainer = styled.div`
  .back-arrow-icon {
    margin: 0;
    transform: rotate(-90deg);
  }
  .back-arrow-icon > path {
    fill: #8D8D8D;
  }
`

const MultipleGoodsPromptContainer = styled.div`
  display: flex; 
  align-items: center;
  gap: 12px;
  .i-icon {
    width: 24px;
    height: 24px;
  }

  @media screen and (max-width: 768px) {
    gap: 10px;
    .i-icon {
      width: 20px;
      height: 20px;
    }
  }
`

const Progressbar = styled.div`
  position: absolute; 
  width: ${p => p.progress || "100%"}; 
  height: 6px; 
  background: ${p => p.color || "#D9D9D9"}; 
  border-radius: 30px;
`

const PhoneNoPrefix = styled.span`
  --color: rgba(153, 153, 153, 1);
  position: absolute; 
  left: 0.75rem; 
  top: 0.8rem; 
  padding-right: 0.5rem; 
  border-right: 1px solid var(--color);
  color: var(--color);
  font-size: 14px; 
  @media (max-width: 768px) {
    top: 0.9rem;
  }
`

export const Input = styled.input`
  width: 100%;
  height: 45px;
  /* border: 0.5px solid rgba(2, 71, 94, 0.3) !important; */
  box-sizing: border-box;
  /* box-shadow: inset 1px 1px 11px rgba(0, 0, 0, 0.1) !important; */
  border-radius: 8px;
  padding: 15px;
  color: #4d4d4d !important;
  text-transform: capitalize;
  ${p => p.style ? {...p.style} : null };

  &[type='email'] {
    text-transform: none;
  }

  &::placeholder {
    color: #b3b3b3;
  }
`;

export const darkModeProceedBtn = {
  background: "#02475E", 
  color: "#fff", 
  border: "1px solid #fff"
} 

export const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;
  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
`;

export const Option = styled.li`
  width: 100%;
  padding: 12px;
  color: #4d4d4d;
  list-style: none;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  border-bottom: 0.5px solid #ececec;
  text-transform: capitalize;

  &:last-of-type {
      border-bottom: none;
  }
`;

export const OptionList = styled.ul`
  position: absolute;
  width: 100%;
  z-index: 1050;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.15));
`;

export const SelectOtherOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    &:last-child {
      align-self: flex-end;
    }
  }
`