import React from 'react';
import styled from 'styled-components';

import Checkbox from './core/Checkbox';
import { Input, InputLabel, mandatoryField } from './LeadFormSteps/common';

import Chevron from '../assets/images/chevron_down.svg';

const OptionItem = ({
  label = '',
  selected = false,
  disabled = false,
  onSelect,
}) => {
  return (
    <Option role="menuitem" disabled={disabled}>
      <Checkbox
        className="option-item"
        value={selected}
        disabled={disabled}
        onChange={() => onSelect(label, selected)}
        text={label}
        textClass="label"
        name="materials-option"
        id={`materials-option-${label}`}
      />
    </Option>
  );
};

const Multiselect = ({ 
  options = [],
  selectedValues = [],
  otherDesc = "",
  otherDescPlaceholder = "Enter here", 
  showOtherOption = false,
  onOptionChange, 
}) => {
  const [areOptionsShown, setAreOptionsShown] = React.useState(false);
  const [selectedOptions, setSelectedOptions] = React.useState(selectedValues);
  const [isOther, setIsOther] = React.useState(() => otherDesc.length);
  const [otherFreeText, setOtherFreeText] = React.useState(otherDesc);

  const multiselectRef = React.useRef(null);
  const otherFreeTextRef = React.useRef(null);

  const handleClickOutside = (e) => {
    if (multiselectRef.current && !multiselectRef.current.contains(e.target)) {
      setAreOptionsShown(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, { passive: true });

    return () =>
      document.removeEventListener('click', handleClickOutside, {
        passive: true,
      });
  });

  const getCombinedOptions = (val) => val.join(';') || '';

  const handleShowOptions = () => {
    setAreOptionsShown(!areOptionsShown);
  };

  const handleSelect = (label, selected) => {
    let updatedOptions = selectedOptions;
    if (selected) {
      updatedOptions = selectedOptions.filter((x) => x !== label);
    } else {
      updatedOptions = [...selectedOptions, label];
    }
    // include or exclude 'free text' if other is selected
    let updatedOpsWithFreeText = [...updatedOptions]
    if (isOther && otherFreeText) {
      updatedOpsWithFreeText = [...updatedOptions, otherFreeText]
    }
    onOptionChange(getCombinedOptions(updatedOpsWithFreeText));
    setSelectedOptions(updatedOptions);
  };

  const onOtherSelect = () => {
    if (!isOther && otherFreeTextRef.current) {
      otherFreeTextRef.current.focus();
      setAreOptionsShown(false);
      onOptionChange(getCombinedOptions([...selectedOptions, otherFreeText]));
    } else {
      onOptionChange(getCombinedOptions(selectedOptions));
    }
    setIsOther(!isOther);
  };

  const handleOtherFreeText = (e) => {
    const val = e.target.value;
    setOtherFreeText(val);
    onOptionChange(getCombinedOptions([...selectedOptions, val]));
  };

  const handleTrimFreeText = e => {
    const val = e.target.value.trim();
    if (val !== otherFreeText) {
      setOtherFreeText(val)
      onOptionChange(getCombinedOptions([...selectedOptions, val]));
    }
  }

  return (
    <>
      <MultiselectContainer ref={multiselectRef} isExpanded={areOptionsShown}>
        <InputContainer role="input" onClick={handleShowOptions}>
          {selectedOptions.length || isOther ? (
            <TagsContainer>
              {selectedOptions?.map((val) => (
                <Tag key={`item-${val}`}>{val}</Tag>
              ))}
              {isOther ? (
                <Tag>Others</Tag>
              ) : null}
            </TagsContainer>
          ) : (
            <PlaceholderText>Select an option</PlaceholderText>
          )}
        </InputContainer>
        <Chevron className="icon" />
        {areOptionsShown ? (
          <OptionsContainer role="menu">
            {options.map((value) => (
              <OptionItem
                selected={selectedOptions?.includes(value)}
                onSelect={handleSelect}
                label={value}
                key={`materials-item-${value}`}
              />
            ))}
            {showOtherOption ? (
              <OptionItem
                selected={isOther}
                onSelect={onOtherSelect}
                label="Others"
                key="other-material"
              />
            ) : null}
          </OptionsContainer>
        ) : null}
      </MultiselectContainer>
      <div style={{ display: isOther ? 'block' : 'none', marginTop: '8px' }}>
        <InputLabel>Description for others {mandatoryField}</InputLabel>
        <Input
          ref={otherFreeTextRef}
          type="text"
          value={otherFreeText}
          onChange={handleOtherFreeText}
          onBlur={handleTrimFreeText}
          placeholder={otherDescPlaceholder}
        />
      </div>
    </>
  );
};

const PlaceholderText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #6c6c6c;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const MultiselectContainer = styled.div`
  width: 100%; 
  position: relative;
  .icon {
    position: absolute;
    right: 16px;
    top: 20px;
    transform: ${p => p.isExpanded ? "rotate(0deg)" : "rotate(180deg)"};
    transition: transform 0.2s ease-in-out;
  }
`

const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  overflow-x: auto;

  /* hiding scrollbars */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  background: #FFFFFF;
  border: 1px solid rgba(118, 118, 118, 0.2);
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 32px;
  cursor: pointer;
`;

const OptionsContainer = styled.div`
  z-index: 99999;
  position: absolute;
  top: 54px;
  width: 100%;
  height: fit-content;
  max-height: 200px;
  overflow: auto;
  background: #ECECEC;
  border-radius: 5px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
  @media screen and (max-width: 768px) {
    max-height: 150px;
  }
`;

const Option = styled.div`
  border-bottom: 1px solid #8d8d8d;
  background: ${(p) => (p.disabled ? 'rgb(200, 200, 200)' : '#fff')};
  .option-item {
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 12px 8px;
  }
  .label {
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    color: #8d8d8d;
    text-align: left;
    cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  }
  @media screen and (max-width: 768px) {
    .label {
      font-size: 14px;
    }
  }
`;

const Tag = styled.div`
  width: fit-content;
  padding: 6px 14px;
  background: rgba(200, 214, 219, 0.3);
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: rgb(102, 102, 102);
  white-space: nowrap;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export default Multiselect;
